import React, { useState } from 'react'
function App() {

  const [display, setDisplay] = useState('none');

  const handleOnclick = () =>{
    setDisplay('block')
  }

  return (
      <div className='card' onClick={handleOnclick}>
        <h1>Is Chester Okay? Click me to find out!</h1>
        <div className='response' style={{display: display}}>
          <ul>
            <li>Symptoms? None</li>
            <li>Tested? Not yet</li>
            <li>Is he going to get tested? If James and Shaina will test positive</li>
            <li>Is he safe? Don't know</li>
            <li>Why don't know? Because life is full of uncertainty, you never know if you are truly safe in a world that is decided by chance</li>
            <li>Eaten lunch? Not yet. Cooking pa rice</li>
          </ul>
        </div>
      </div>
    );

}

export default App;
